import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/events/event-bookings",
  },
  {
    path: "/rsvp/:slug/:id",
    name: "EventRegistration",
    component: () =>
      import(/* webpackChunkName: "public" */ "../components/rsvp/index"),
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "../components/auth/SignIn"),
  },
  //region General
  {
    path: "/general/document-types",
    name: "DocumentTypes",
    secure: true,
    component: () =>
      import(
        /* webpackChunkName: "document-types" */ "../components/general/documenttypes/index"
      ),
  },
  {
    path: "/general/genders",
    name: "Genders",
    component: () =>
      import(
        /* webpackChunkName: "genders" */ "../components/general/genders/index"
      ),
  },
  {
    path: "/general/nationalitys",
    name: "Nationalitys",
    component: () =>
      import(
        /* webpackChunkName: "nationalitys" */ "../components/general/nationalitys/index"
      ),
  },
  //endregion General
  //region Events
  {
    path: "/events/events",
    name: "Events",
    component: () =>
      import(
        /* webpackChunkName: "events" */ "../components/events/events/index"
      ),
  },
  {
    path: "/events/event-locations",
    name: "EventLocations",
    component: () =>
      import(
        /* webpackChunkName: "event-locations" */ "../components/events/eventlocations/index"
      ),
  },
  {
    path: "/events/event-bookings",
    name: "EventBookings",
    component: () =>
      import(
        /* webpackChunkName: "event-bookings" */ "../components/events/eventbookings/index"
      ),
  },
  {
    path: "/events/event-types",
    name: "EventTypes",
    component: () =>
      import(
        /* webpackChunkName: "event-types" */ "../components/events/eventtypes/index"
      ),
  },
  //endregion Events
  //region Geographics
  {
    path: "/geo/countrys",
    name: "Countrys",
    component: () =>
      import(
        /* webpackChunkName: "countrys" */ "../components/geo/countrys/index"
      ),
  },
  {
    path: "/geo/atolls",
    name: "Atolls",
    component: () =>
      import(/* webpackChunkName: "atolls" */ "../components/geo/atolls/index"),
  },
  {
    path: "/geo/islands",
    name: "Islands",
    component: () =>
      import(
        /* webpackChunkName: "islands" */ "../components/geo/islands/index"
      ),
  },
  //endregion Geographics
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "SignIn" && to.secure && this.empty(this.user)) {
    next({ name: "SignIn" });
  } else next();
});
export default router;
