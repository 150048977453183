import Vue from "vue";
import Swal from "sweetalert2";
import moment from "moment";
import Repo, { baseUrl } from "../utils/repo";
export default Vue.mixin({
  data() {
    return {
      userMenus: [
        { id: 1, text: "Profile", link: "/profile" },
        { id: 2, text: "Reminders", link: "/reminders" },
      ],
      selectedMenu: null,
      futurePickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        },
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pastPickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now();
        },
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  computed: {
    user() {
      return !this.empty(localStorage.getItem("hp_user"))
        ? JSON.parse(localStorage.getItem("hp_user"))
        : "";
    },
    token() {
      return localStorage.getItem("hp_token");
    },
    menu() {
      return !this.empty(localStorage.getItem("hp_menu"))
        ? JSON.parse(localStorage.getItem("hp_menu"))
        : [];
    },
  },
  methods: {
    signOut() {
      this.loading = false;
      localStorage.setItem("hp_user", "");
      localStorage.setItem("hp_token", "");
      localStorage.setItem("menu", "");
      document.location.href = "/";
      this.api("auth/logout")
        .then(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    empty(text) {
      if (text == "" || text == undefined) {
        return true;
      } else {
        return false;
      }
    },
    async api(api, post_data) {
      let self = this;
      var promise = await new Promise(function (resolve, reject) {
        Repo.post(`${baseUrl}` + api, post_data)
          .then((response) => {
            resolve(response.data);
            return response.data;
          })
          .catch(function (error) {
            if (
              error.response.status == 401 &&
              self.$router.currentRoute.path != "/sign-in"
            ) {
              self.$router.push("/sign-in");
              return;
            } else if (error.response.status == 401) {
              self.showError("Email or Password incorrect");
            } else {
              self.handleErrors(error);
            }
            reject(error);
            return error;
          });
      });
      return promise;
    },
    normalizeDate(date) {
      return new Date(date.getTime() + date.getTimezoneOffset());
    },
    formatDateTable(col, row, value) {
      return this.formatDate(value);
    },
    formatDate(value, format = "YYYY-MM-DD") {
      if (this.empty(value)) return "";
      return moment(value).format(format);
    },
    formatDateTime(value, format = "YYYY-MM-DD HH:mm:ss") {
      //return this.normalizeDate(value);
      if (this.empty(value)) return "";
      return moment(value).format(format);
    },
    formatTime(value, format = "HH:mm:ss") {
      if (this.empty(value)) return "";
      if (value.length < 8) {
        var newDate = new Date();
        value = newDate.toDateString() + " " + value;
      }
      return moment(value).format(format);
    },
    getDatTime(value) {
      if (this.empty(value)) return "";
      var newDate = new Date();
      value = newDate.toDateString() + " " + value;
      return value;
    },
    toTitleCase(str) {
      if (this.empty(str)) return;
      str = str.replaceAll("_", " ");
      str = str.replaceAll("-", " ");
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    },
    async sendSmsMessage(mobile_no, message, target_audiences_id) {
      let f = {
        mobile_no: mobile_no,
        message: message,
        target_audiences_id: target_audiences_id,
      };
      await Repo.post(`${baseUrl}send-sms`, f)
        .then((response) => {
          return response.data;
        })
        .catch(function (error) {
          return error;
        });
    },
    formatUpperCase(value) {
      return value.toUpperCase();
    },
    displayError(msg) {
      if (msg.response.data.message == "Unauthenticated.") {
        this.$router.push("/signIn");
      } else if (msg.response.status == 422) {
        //Validation Errors
        let er = msg.response.data.error;
        let erMsg = "";
        if (typeof er === "object") {
          /*for (var key in er) {
            if (er.hasOwnProperty(key)) {
              erMsg += er[key][0] + " <br/>";
            }
          }*/
        } else {
          erMsg = er;
        }
        if (!this.empty(erMsg)) {
          erMsg = '<span class="text-danger">' + erMsg + "</span>";
          if (msg.response.data.additional_info)
            erMsg +=
              '<br/><span class="text-warning">' +
              msg.data.additional_info +
              "</span>";
          Swal.fire({
            title: "Warning!",
            html: erMsg,
            type: "warning",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {});
        }
        return;
      }
    },
    makeToast(variant = null, msg) {
      if (!this.empty(msg.response)) {
        this.displayError(msg);
        return;
      }
      this.$bvToast.toast(msg, {
        title: ` ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },
    handleErrors(error) {
      if (!error.response) {
        Swal.fire({
          title: "Error!",
          html: "Could not connect to the server.",
          icon: "error",
          confirmButtonText: "Alright",
        });
        return;
      }
      if (
        error.response.status == 422 &&
        !this.empty(error.response.data.valid)
      ) {
        let validation = error.response.data.valid;
        let errors = "";
        Object.keys(validation).forEach((key) => {
          let e = validation[key];
          e.forEach((item) => {
            errors += item + "<br/>";
          });
        });
        Swal.fire({
          title: "Validations Failed!",
          html: errors,
          icon: "error",
          confirmButtonText: "Alright",
        });
      } else if (
        error.response.status == 422 &&
        !this.empty(error.response.data.error)
      ) {
        Swal.fire({
          title: "Validations Failed!",
          html: error.response.data.error,
          icon: "error",
          confirmButtonText: "Alright",
        });
      } else if (
        error.response.status == 500 &&
        !this.empty(error.response.data.error)
      ) {
        Swal.fire({
          title: "Error!",
          html: error.response.data.error,
          icon: "error",
          confirmButtonText: "Alright",
        });
      } else if (!this.empty(error.response.data.error)) {
        Swal.fire({
          title: "Error!",
          html: error.response.data.error,
          icon: "error",
          confirmButtonText: "Alright",
        });
        if (error.response.data.error == "Time limit reached") {
          this.signOut();
          this.$router.push("/signIn");
        }
      } else {
        Swal.fire({
          title: "Error!",
          html: "Oops!, unkown Error",
          icon: "error",
          confirmButtonText: "Alright",
        });
      }
    },
    showError(error) {
      Swal.fire({
        title: "Error!",
        html: error,
        icon: "error",
        confirmButtonText: "Alright",
      });
    },
    showInfo(message) {
      Swal.fire({
        title: "Cool!",
        html: message,
        icon: "info",
        confirmButtonText: "Cool",
      });
    },
  },
});
