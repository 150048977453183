<template>
  <div>
    <template v-if="!empty(user)">
      <v-app-bar app color="primary" dark class="hidden-sm-and-down">
        <template>
          <!-- top Bar with Logo and User Menus -->
          <div class="d-flex align-center">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src=""
              transition="scale-transition"
              width="40"
            />

            <v-img
              alt="Vuetify Name"
              class="shrink mt-1 hidden-sm-and-down"
              contain
              min-width="100"
              src=""
              width="100"
            />
          </div>

          <v-spacer></v-spacer>

          <div class="hidden-sm-and-down">
            <el-menu
              :default-active="activeMenu"
              class="el-menu-demo"
              mode="horizontal"
            >
              <template v-for="(m, index) in menu">
                <template v-if="m.is_module">
                  <el-menu-item :index="'m' + index" :key="index">
                    {{ m.module }}
                  </el-menu-item>
                </template>
                <template v-else>
                  <el-submenu :index="'s' + index" :key="'s' + index">
                    <template slot="title">{{ m.module }}</template>
                    <template v-for="(s, sIndex) in m.SubModules">
                      <template v-if="s.is_module == true">
                        <el-menu-item
                          :index="index + '-' + sIndex"
                          :key="index + '-' + sIndex"
                          @click="gotoLink(s)"
                        >
                          {{ s.module }}
                        </el-menu-item>
                      </template>
                      <template v-else>
                        <el-submenu
                          :index="'c' + index + '-' + sIndex"
                          :key="'c' + index + '-' + sIndex"
                        >
                          <template slot="title">{{ s.module }}</template>
                          <template v-for="(c, cIndex) in s.SubModules">
                            <el-menu-item
                              :index="index + '-' + sIndex + '-' + cIndex"
                              :key="index + '-' + sIndex + '-' + cIndex"
                              @click="gotoLink(c)"
                            >
                              {{ c.module }}
                            </el-menu-item>
                          </template>
                        </el-submenu>
                      </template>
                    </template>
                  </el-submenu>
                </template>
              </template>
            </el-menu>
          </div>

          <v-spacer></v-spacer>

          <v-btn small icon>
            <v-icon light color="white">mdi-magnify</v-icon>
          </v-btn>

          <v-btn small icon color="white">
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>

          <v-menu rounded="lg" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-avatar size="24" class="ml-2" v-bind="attrs" v-on="on">
                <img src="../../assets/face.png" />
              </v-avatar>
            </template>
            <v-list dense>
              <v-list-item-group v-model="selectedMenu" color="primary">
                <v-list-item v-for="menu in userMenus" :key="menu.id">
                  <v-list-item-content>
                    <v-list-item-title v-text="menu.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="'Log Out'"
                      @click="signOut"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-app-bar>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import { Menu, MenuItem, Submenu } from "element-ui";
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
export default {
  components: {},
  data() {
    return {
      subMenus: [],
      links: [],
      activeMenu: "m0",
      activeLink: -1,
    };
  },
  created() {
    if (!this.empty(this.menu)) {
      this.subMenus = this.menu[0].SubModules;
      this.links = this.subMenus[0].SubModules;
    }
  },
  methods: {
    showMenu(menu) {
      this.subMenus = menu.SubModules;
      this.links = this.subMenus[0].SubModules;
      this.activeMenu = 0;
      this.activeLink = 0;
    },
    getLinks(menu) {
      this.links = menu.SubModules;
      this.activeLink = 0;
    },
    gotoLink(link) {
      console.log(link.route_path);
      this.$router.push(link.route_path);
    },
  },
  name: "Header",
};
</script>
<style>
.v-toolbar__extension {
  background: #3c9bf9 !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
  background-color: #1976d2;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #ffffff !important;
}
.el-submenu__title {
  color: #53b0f1 !important;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent !important;
}
</style>
