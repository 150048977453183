<template>
  <v-app>
    <template v-if="!empty(user) && !rsvp">
      <full-header />
      <mobile-header></mobile-header>
    </template>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import MobileHeader from "./components/header/MobileHeader.vue";
import FullHeader from "./components/header/Header.vue";
export default {
  components: {
    MobileHeader,
    FullHeader,
  },
  created() {
    console.log(this.$route.path);
  },
  name: "App",
  computed: {
    rsvp() {
      return this.$route.path.includes("rsvp");
    },
  },
};
</script>
<style>
.v-toolbar__extension {
  background: #3c9bf9 !important;
}
</style>
